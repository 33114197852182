export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout@base.svelte"),
	() => import("../../src/routes/__error.svelte"),
	() => import("../../src/routes/__layout-base.svelte"),
	() => import("../../src/routes/__layout-content@shell.svelte"),
	() => import("../../src/routes/__layout-marketing@shell.svelte"),
	() => import("../../src/routes/__layout-noNav.svelte"),
	() => import("../../src/routes/__layout-reset@base.svelte"),
	() => import("../../src/routes/__layout-shell@base.svelte"),
	() => import("../../src/routes/account/__layout@noNav.svelte"),
	() => import("../../src/routes/app/__layout@reset.svelte"),
	() => import("../../src/routes/app/teach/__layout@reset.svelte"),
	() => import("../../src/routes/experiments/__layout@content.svelte"),
	() => import("../../src/routes/teach/__layout@marketing.svelte"),
	() => import("../../src/routes/teach/resources/memes/view/__layout@reset.svelte"),
	() => import("../../src/routes/account/access-pack.svelte"),
	() => import("../../src/routes/account/connect/[oAuthProviderId]@noNav.svelte"),
	() => import("../../src/routes/account/connect/clever-teacher/authenticate/index@noNav.svelte"),
	() => import("../../src/routes/account/connect/clever-teacher/index@noNav.svelte"),
	() => import("../../src/routes/account/login-required.svelte"),
	() => import("../../src/routes/account/password-reset/[tokenId].svelte"),
	() => import("../../src/routes/account/password-reset/password-reset-error.svelte"),
	() => import("../../src/routes/app/checkpoints/[checkpointId]/attempts/[attemptId].svelte"),
	() => import("../../src/routes/app/checkpoints/[checkpointId]/index.svelte"),
	() => import("../../src/routes/app/components/grade-table-demo.svelte"),
	() => import("../../src/routes/app/components/index.svelte"),
	() => import("../../src/routes/app/dashboard/edit-profile.svelte"),
	() => import("../../src/routes/app/dashboard/index.svelte"),
	() => import("../../src/routes/app/dashboard/upgrade-to-teacher.svelte"),
	() => import("../../src/routes/app/diagnostic/[testId].svelte"),
	() => import("../../src/routes/app/lessons/[lessonId]/1.svelte"),
	() => import("../../src/routes/app/lessons/[lessonId]/[lessonPageId].svelte"),
	() => import("../../src/routes/app/lessons/[lessonId]/finish.svelte"),
	() => import("../../src/routes/app/lessons/[lessonId]/index.svelte"),
	() => import("../../src/routes/app/lessons/[lessonId]/start.svelte"),
	() => import("../../src/routes/app/lessons/index.svelte"),
	() => import("../../src/routes/app/messages/[messageId].svelte"),
	() => import("../../src/routes/app/skills/index.svelte"),
	() => import("../../src/routes/app/skills/practice/index.svelte"),
	() => import("../../src/routes/app/skills/practice/start/[topicId].svelte"),
	() => import("../../src/routes/app/skills/start/index.svelte"),
	() => import("../../src/routes/app/subscription/index.svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/assignments/checkpoint-[checkpointId].svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/assignments/lesson-[lessonId].svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/assignments/skill-[topicId].svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/assignments/test-[testId].svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/lessons.svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/skills.svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/students/ResyncCleverSectionButton.svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/students/[studentId]/checkpoints/[checkpointId]/index.svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/students/[studentId]/index.svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/students/[studentId]/skills/[topicId].svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/students/[studentId]/tests/[testId].svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/students/index.svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/teachers.svelte"),
	() => import("../../src/routes/app/teach/classes/[sectionId]/tests.svelte"),
	() => import("../../src/routes/app/teach/classes/index.svelte"),
	() => import("../../src/routes/app/teach/index.svelte"),
	() => import("../../src/routes/app/teach/licenses/[licenseType]/[licenseId]/invoice.svelte"),
	() => import("../../src/routes/app/teach/licenses/[licenseType]/[licenseId]/receipt.svelte"),
	() => import("../../src/routes/app/teach/licenses/index.svelte"),
	() => import("../../src/routes/app/teach/licenses/purchase.svelte"),
	() => import("../../src/routes/app/teach/teachers/index.svelte"),
	() => import("../../src/routes/app/teach/tests/[testId]/classes.svelte"),
	() => import("../../src/routes/app/teach/tests/[testId]/contents.svelte"),
	() => import("../../src/routes/app/teach/tests/[testId]/results/[attemptId].svelte"),
	() => import("../../src/routes/app/teach/tests/[testId]/results/index.svelte"),
	() => import("../../src/routes/app/teach/tests/[testId]/teachers.svelte"),
	() => import("../../src/routes/app/teach/tests/index.svelte"),
	() => import("../../src/routes/app/tests/[testId]/attempts/[attemptId].svelte"),
	() => import("../../src/routes/app/tests/[testId]/attempts/index.svelte"),
	() => import("../../src/routes/app/tests/[testId]/index.svelte"),
	() => import("../../src/routes/app/tests/[testId]/run.svelte"),
	() => import("../../src/routes/app/tests/index.svelte"),
	() => import("../../src/routes/experiments/autocorrelate.svelte"),
	() => import("../../src/routes/experiments/chord-analyzer.svelte"),
	() => import("../../src/routes/experiments/index.svelte"),
	() => import("../../src/routes/experiments/interactive.svelte"),
	() => import("../../src/routes/experiments/name-the-rhythm.svelte"),
	() => import("../../src/routes/experiments/pitchy-fish.svelte"),
	() => import("../../src/routes/experiments/rhythm-recorder/index.svelte"),
	() => import("../../src/routes/experiments/rhythm-validator/RhythmDisplay.svelte"),
	() => import("../../src/routes/experiments/rhythm-validator/index.svelte"),
	() => import("../../src/routes/experiments/solfege-sally.svelte"),
	() => import("../../src/routes/experiments/spice.svelte"),
	() => import("../../src/routes/experiments/tuning-systems.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/invites/class/[inviteLink]@noNav.svelte"),
	() => import("../../src/routes/invites/institution/[institutionInviteToken]@noNav.svelte"),
	() => import("../../src/routes/invites/test/[testInviteLink]@noNav.svelte"),
	() => import("../../src/routes/maintenance.svelte"),
	() => import("../../src/routes/privacy@content.svelte"),
	() => import("../../src/routes/teach/index@reset.svelte"),
	() => import("../../src/routes/teach/quote.svelte"),
	() => import("../../src/routes/teach/resources/index.svelte"),
	() => import("../../src/routes/teach/resources/kodaly-curwen-solfege-hand-signs-font.svelte"),
	() => import("../../src/routes/teach/resources/memes/index.svelte"),
	() => import("../../src/routes/teach/resources/memes/view/[memeFileName].svelte")
];

export const dictionary = {
	"": [[2, 0, 85], [1, 1]],
	"experiments": [[2, 7, 3, 11, 75], [1, 1, 1]],
	"maintenance": [[2, 0, 89], [1, 1]],
	"privacy@content": [[2, 7, 3, 90], [1, 1, 1]],
	"teach@reset": [[2, 6, 91], [1, 1]],
	"account/access-pack": [[5, 8, 14], [1]],
	"account/login-required": [[5, 8, 18], [1]],
	"app/components": [[2, 6, 9, 24], [1, 1]],
	"app/dashboard": [[2, 6, 9, 26], [1, 1]],
	"app/lessons": [[2, 6, 9, 34], [1, 1]],
	"app/skills": [[2, 6, 9, 36], [1, 1]],
	"app/subscription": [[2, 6, 9, 40], [1, 1]],
	"app/teach": [[2, 6, 10, 56], [1, 1]],
	"app/tests": [[2, 6, 9, 72], [1, 1]],
	"experiments/autocorrelate": [[2, 7, 3, 11, 73], [1, 1, 1]],
	"experiments/chord-analyzer": [[2, 7, 3, 11, 74], [1, 1, 1]],
	"experiments/interactive": [[2, 7, 3, 11, 76], [1, 1, 1]],
	"experiments/name-the-rhythm": [[2, 7, 3, 11, 77], [1, 1, 1]],
	"experiments/pitchy-fish": [[2, 7, 3, 11, 78], [1, 1, 1]],
	"experiments/rhythm-recorder": [[2, 7, 3, 11, 79], [1, 1, 1]],
	"experiments/rhythm-validator": [[2, 7, 3, 11, 81], [1, 1, 1]],
	"experiments/solfege-sally": [[2, 7, 3, 11, 82], [1, 1, 1]],
	"experiments/spice": [[2, 7, 3, 11, 83], [1, 1, 1]],
	"experiments/tuning-systems": [[2, 7, 3, 11, 84], [1, 1, 1]],
	"teach/quote": [[2, 7, 4, 12, 92], [1, 1, 1]],
	"teach/resources": [[2, 7, 4, 12, 93], [1, 1, 1]],
	"account/connect/clever-teacher@noNav": [[5, 17], [1]],
	"account/password-reset/password-reset-error": [[5, 8, 20], [1]],
	"app/components/grade-table-demo": [[2, 6, 9, 23], [1, 1]],
	"app/dashboard/edit-profile": [[2, 6, 9, 25], [1, 1]],
	"app/dashboard/upgrade-to-teacher": [[2, 6, 9, 27], [1, 1]],
	"app/skills/practice": [[2, 6, 9, 37], [1, 1]],
	"app/skills/start": [[2, 6, 9, 39], [1, 1]],
	"app/teach/classes": [[2, 6, 10, 55], [1, 1]],
	"app/teach/licenses": [[2, 6, 10, 59], [1, 1]],
	"app/teach/teachers": [[2, 6, 10, 61], [1, 1]],
	"app/teach/tests": [[2, 6, 10, 67], [1, 1]],
	"experiments/rhythm-validator/RhythmDisplay": [[2, 7, 3, 11, 80], [1, 1, 1]],
	"teach/resources/kodaly-curwen-solfege-hand-signs-font": [[2, 7, 4, 12, 94], [1, 1, 1]],
	"teach/resources/memes": [[2, 7, 4, 12, 95], [1, 1, 1]],
	"account/connect/clever-teacher/authenticate@noNav": [[5, 16], [1]],
	"app/teach/licenses/purchase": [[2, 6, 10, 60], [1, 1]],
	"app/skills/practice/start/[topicId]": [[2, 6, 9, 38], [1, 1]],
	"teach/resources/memes/view/[memeFileName]": [[2, 6, 13, 96], [1, 1]],
	"app/teach/classes/[sectionId]/lessons": [[2, 6, 10, 45], [1, 1]],
	"app/teach/classes/[sectionId]/skills": [[2, 6, 10, 46], [1, 1]],
	"app/teach/classes/[sectionId]/students": [[2, 6, 10, 52], [1, 1]],
	"app/teach/classes/[sectionId]/teachers": [[2, 6, 10, 53], [1, 1]],
	"app/teach/classes/[sectionId]/tests": [[2, 6, 10, 54], [1, 1]],
	"app/teach/tests/[testId]/classes": [[2, 6, 10, 62], [1, 1]],
	"app/teach/tests/[testId]/contents": [[2, 6, 10, 63], [1, 1]],
	"app/teach/tests/[testId]/results": [[2, 6, 10, 65], [1, 1]],
	"app/teach/tests/[testId]/teachers": [[2, 6, 10, 66], [1, 1]],
	"app/teach/classes/[sectionId]/students/ResyncCleverSectionButton": [[2, 6, 10, 47], [1, 1]],
	"app/teach/classes/[sectionId]/assignments/checkpoint-[checkpointId]": [[2, 6, 10, 41], [1, 1]],
	"app/teach/classes/[sectionId]/assignments/lesson-[lessonId]": [[2, 6, 10, 42], [1, 1]],
	"app/teach/classes/[sectionId]/assignments/skill-[topicId]": [[2, 6, 10, 43], [1, 1]],
	"app/teach/classes/[sectionId]/assignments/test-[testId]": [[2, 6, 10, 44], [1, 1]],
	"app/teach/classes/[sectionId]/students/[studentId]": [[2, 6, 10, 49], [1, 1]],
	"app/teach/tests/[testId]/results/[attemptId]": [[2, 6, 10, 64], [1, 1]],
	"app/teach/classes/[sectionId]/students/[studentId]/checkpoints/[checkpointId]": [[2, 6, 10, 48], [1, 1]],
	"app/teach/classes/[sectionId]/students/[studentId]/skills/[topicId]": [[2, 6, 10, 50], [1, 1]],
	"app/teach/classes/[sectionId]/students/[studentId]/tests/[testId]": [[2, 6, 10, 51], [1, 1]],
	"app/teach/licenses/[licenseType]/[licenseId]/invoice": [[2, 6, 10, 57], [1, 1]],
	"app/teach/licenses/[licenseType]/[licenseId]/receipt": [[2, 6, 10, 58], [1, 1]],
	"account/connect/[oAuthProviderId]@noNav": [[5, 15], [1]],
	"invites/class/[inviteLink]@noNav": [[5, 86], [1]],
	"invites/institution/[institutionInviteToken]@noNav": [[5, 87], [1]],
	"invites/test/[testInviteLink]@noNav": [[5, 88], [1]],
	"account/password-reset/[tokenId]": [[5, 8, 19], [1]],
	"app/checkpoints/[checkpointId]": [[2, 6, 9, 22], [1, 1]],
	"app/diagnostic/[testId]": [[2, 6, 9, 28], [1, 1]],
	"app/lessons/[lessonId]": [[2, 6, 9, 32], [1, 1]],
	"app/messages/[messageId]": [[2, 6, 9, 35], [1, 1]],
	"app/tests/[testId]": [[2, 6, 9, 70], [1, 1]],
	"app/lessons/[lessonId]/1": [[2, 6, 9, 29], [1, 1]],
	"app/lessons/[lessonId]/finish": [[2, 6, 9, 31], [1, 1]],
	"app/lessons/[lessonId]/start": [[2, 6, 9, 33], [1, 1]],
	"app/tests/[testId]/attempts": [[2, 6, 9, 69], [1, 1]],
	"app/tests/[testId]/run": [[2, 6, 9, 71], [1, 1]],
	"app/checkpoints/[checkpointId]/attempts/[attemptId]": [[2, 6, 9, 21], [1, 1]],
	"app/tests/[testId]/attempts/[attemptId]": [[2, 6, 9, 68], [1, 1]],
	"app/lessons/[lessonId]/[lessonPageId]": [[2, 6, 9, 30], [1, 1]]
};